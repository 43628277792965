import React from "react"
import * as Icon from 'react-feather';

const Modal = ({ children, closeModal }) => {
    return (
        <>
            <div className='modal-container' onClick={(event) => {
                if (event.target.classList.contains('modal-overlay')) {
                    closeModal();
                }
            }}>
                <div className="modal-overlay">
                </div>
                <div className="modal-holder">
                    <div className="modal">
                        <div className="modal-inner">
                            {children}
                            <button className="modal-close" onClick={() => closeModal()}>
                                <Icon.X size={24} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal;