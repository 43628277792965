import * as React from "react"
import { useState, useEffect } from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Modal from "../components/Modal"
import CookieSettings from "../components/cookie-settings"
import Cookies from "universal-cookie"

const CookiesPolicy = () => {

    const [showCookies, setShowCookies] = useState(false);
    const [cookiePrefs, setCookiePrefs] = useState({ performance: false, analytics: false })
    const cookieVersion = "1.0"
    const cookieExpirey = 30 * 24 * 60 * 60
    const cookies = new Cookies()
    const cookie = cookies.get("rtv_prefs")

    const submitForm = (e) => {
        e.preventDefault()
        var data = new FormData(e.target)
        cookies.set(
            'rtv_prefs',
            JSON.stringify({
                set: true,
                performance: data.get('performance') != null,
                version: cookieVersion
            }),
            { maxAge: cookieExpirey, sameSite: "lax", secure: true }
        )
        window.location.reload();

    }

    const getCookieSetting = (settingName) => {
        return (cookie) ? cookie[settingName] : false
    }

    useEffect(() => {
        setCookiePrefs({
            performance: getCookieSetting('performance'),
        })
    }, [])

    return (
        <Layout>
            <Seo title="Cookies Policy" />
            <div className="container mt-3 firstPanel">
                <section>
                    <div className="mb-2 legal-text">
                        <h2 className="h1 mb-2">Cookies</h2>
                        <p>Cookies are small data files placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
                        <p>You can choose to accept or modify the settings of certain cookies.</p>
                        <button onClick={() => setShowCookies(true)} className="btn btn-muted">Manage Cookie Settings</button>
                        <h3 className="mt-2">Cookie Types</h3>
                        <h4>First Party Cookies</h4>
                        <p>These are cookies set by us, either for “essential” reasons to let the website operate or to help us better understand users and enhance their experience.</p>
                        <h4>Third Party Cookies</h4>
                        <p>Third party cookies enable third party features or functionality to be provided on or through the website (e.g. like advertising, interactive content and analytics). Some are essential for functionality. The parties that set these third party cookies can recognize your computer both when you visit the website in question and also when you visit certain other websites.</p>
                        <h3>How do we use cookies?</h3>
                        <p>The specific types of first and third party cookies served through this Website and the purposes they perform are described below:</p>
                        <h4>Essential Cookies</h4>
                        <p>These cookies are strictly necessary to provide you with services available through our Websites and to use some of its features, such as access to secure areas.</p>
                    </div>
                    <table className="legal_table">
                        <thead>
                            <tr>
                                <th className="legal_table_colSmall">Name</th>
                                <th>Purpose</th>
                                <th>Type/Provider</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="legal_table_colSmall">rtv_prefs</td>
                                <td>Used to record whether you have consented to our cookie policy along with your preferences of the type of cookies you accepted.</td>
                                <td>First Party (https cookie)</td>
                            </tr>
                            <tr>
                                <td className="legal_table_colSmall">AWSALBCORS</td>
                                <td>This cookie is managed by Amazon AWS and is used for load balancing</td>
                                <td>Third Party (https cookie)</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="mb-2 legal-text">
                        <h4 className="mt-2">Performance Cookies</h4>
                        <p>These cookies are used to enhance the performance and functionality of our Websites but are non-essential to their use. However, without these cookies, certain functionality (like videos) may become unavailable.</p>
                    </div>
                    <table className="legal_table">
                        <thead>
                            <tr>
                                <th className="legal_table_colSmall">Category</th>
                                <th className="legal_table_colSmall">Name</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="legal_table_colSmall">Vimeo</td>
                                <td className="legal_table_colSmall">
                                    <ul>
                                        <li>__cf_bm</li>
                                        <li>vuid</li>
                                        <li>player</li>
                                        <li>sync_active</li>
                                    </ul>
                                </td>
                                <td>
                                    <div>
                                        We use Vimeo to embed videos onto our site.<br />
                                        Vimeo will drop third party cookies to enable the the video to play and to collect analytics data such as how long a viewer has watched the video.<br />
                                        These cookies do not track individuals unless you are simultaneously logged in to a Vimeo account when Vimeo may collect tracking data about your interaction with these videos.<br />
                                        <a href="https://vimeo.com/privacy">vimeo.com/privacy</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <div className="mb-2 legal-text">
                        <h4 className="mt-2">Analytics & Customization Cookies</h4>
                        <p>These cookies collect information that is used either in aggregate form to help us understand how our Websites are being used or how effective our marketing campaigns are, or to help us customize our Websites for you.</p>
                    </div>
                    <table className="legal_table">
                        <thead>
                            <tr>
                                <th className="legal_table_colSmall">Category</th>
                                <th className="legal_table_colSmall">Name</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Google Analytics</td>
                                <td>
                                    <ul>
                                        <li>_ga</li>
                                        <li>_ga_ZM7Y85EFLG</li>
                                    </ul>
                                </td>
                                <td>
                                    <div>
                                        We use Google Analytics to collect information about how visitors use our website.<br />
                                        We use the information to compile reports and to help us improve the websites.<br />
                                        The cookies collect information in a way that does not directly identify anyone. The data includes the number of visitors to the website and blog, where visitors have come to the website from and the pages they visited.<br />
                                        <a href="https://support.google.com/analytics/answer/6004245">support.google.com/analytics/answer/6004245</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                </section>

                <section className="mt-2 mb-3">
                    <h3>Who on our team has access?</h3>
                    <p>Members of our team have access to the information you provide us.</p>
                </section>

            </div>

            {showCookies &&
                <Modal closeModal={() => setShowCookies(false)}>
                    <CookieSettings submitHandler={(e) => submitForm(e)} settings={cookiePrefs} />
                </Modal>
            }
        </Layout>
    )
}

export default CookiesPolicy